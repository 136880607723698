













































import wAxios from '@/plugins/w/axios'

export default {

	components: {
		'a-question-choices': () => import('@/plugins/app@components/exam/a-question-choices.vue'),
		'a-question-header': () => import('@/plugins/app@components/exam/a-question-header.vue'),
		'z-loader': () => import('@/plugins/app@components/loader/z-loader.vue'),
	},

	data() {
		return {
			loading: false,
			results: null,
			questionOrder: parseInt(this.$route.params.question) || 1,
		}
	},

	computed: {
		questionsCount: function() {
			return this.results?.questions_with_answers?.length || 1
		},
		question: function() {
			return this.results?.questions_with_answers[this.questionOrder - 1]
		}
	},

	async mounted() {
		try {
			this.loading = true
			const params = this.$route.params
			const { data: { data: results } } = await wAxios.get_auth(
				`student/exams/${params.examhash}/student/${params.id}`
			)
			this.results = results
		} catch (err) {
			console.error(err)
		} finally {
			this.loading = false
		}
	},

	methods: {
		back() {
			this.questionOrder--
		},

		forward() {
			this.questionOrder++
		},
	}
}
